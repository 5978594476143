import React from "react"
import { Link } from "gatsby"

const ContactMe = () => {
  return (
    <Link to="/kontakt" className="contact-btn color-bg">
      <i className="fal fa-envelope"></i>
      <span>Kontaktieren Sie mich</span>
    </Link>
  )
}

export default ContactMe
