import React, { useContext } from "react"
import { Button, Form, FormGroup, Label, Input, FormText } from "reactstrap"
import SEO from "../components/seo"
import { Link, StaticQuery, graphql } from "gatsby"
import { Parallax } from "react-scroll-parallax"
import Img from "gatsby-image"
import LayoutContext from "../context/LayoutContext"
import ContactMe from "../components/contact/ContactMe"

const NotFoundPage = () => {
  const layoutContext = useContext(LayoutContext)

  layoutContext.update({ layout: "main" })
  return (
    <StaticQuery
      query={graphql`
        {
          backgroundImage: file(relativePath: { eq: "all/matrix.jpg" }) {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={data => {
        return (
          <>
            <SEO title="404: Not found" />
            <div id="wrapper" className="single-page-wrap">
              <div className="content">
                <div className="single-page-decor"></div>
                <div className="single-page-fixed-row">
                  <div className="scroll-down-wrap">
                    <div className="mousey">
                      <div className="scroller"></div>
                    </div>
                    <span>Runter scrollen</span>
                  </div>
                  <Link to="/" className="single-page-fixed-row-link">
                    <i className="fal fa-arrow-left"></i>
                    <span>Zum Start</span>
                  </Link>
                </div>

                <section className="parallax-section fw-parallax">
                  <Parallax className="bg par-elem" y={["-400px", "400px"]}>
                    <Img
                      className="imgbg"
                      fluid={data.backgroundImage.childImageSharp.fluid}
                      alt="Background"
                    />
                  </Parallax>

                  <div className="overlay"></div>
                  <div className="container">
                    <div className="error-wrap">
                      <h2>404</h2>
                      <p>
                        Es tut mir leid, aber die Seite, die Sie gesucht haben,
                        konnte nicht gefunden werden
                      </p>
                      <div className="clearfix"></div>
                      <Form action="#">
                        <Input
                          name="se"
                          id="se"
                          type="text"
                          className="search"
                          placeholder="Suchen..."
                        />
                        <Button
                          className="search-submit color-bg"
                          id="submit_btn"
                          style={{ lineHeight: "unset", marginTop: "0" }}
                        >
                          <i className="fa fa-search"></i>
                        </Button>
                      </Form>
                      <div className="clearfix"></div>
                      <p>oder</p>
                      <Link to="/" className="btn flat-btn color-btn">
                        Zum Start
                      </Link>
                    </div>
                  </div>
                </section>

                <section className="dark-bg2 small-padding order-wrap">
                  <div className="container">
                    <div className="row">
                      <div className="col-md-8">
                        <h3>
                          Mehr Informationen ? Besuchen Sie mein Portfolio
                        </h3>
                      </div>
                      <div className="col-md-4">
                        <Link
                          to="/portfolio"
                          className="btn flat-btn color-btn"
                        >
                          Mein Portfolio
                        </Link>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <div className="height-emulator fl-wrap"></div>

              <ContactMe />
            </div>
          </>
        )
      }}
    ></StaticQuery>
  )
}

export default NotFoundPage
